/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap');

* {
	font-family: 'Jost', sans-serif;
}

.path-bg {
	/* background-position: center -3.5em; */
	background-position: center bottom -2em;
}

.module-content p {
	padding: 0.5em 0;
}

/* .swiper-wrapper {
	display: grid !important;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto auto;
	grid-auto-flow: row;
}

.swiper-wrapper .swiper-slide {
	height: fit-content !important;
} */

/* .module-content h3 {
  font-size: 1.5em;
  color: #afb2b2;
} */

.MuiLinearProgress-root {
	height: 10px !important;
	border-radius: 10px !important;
}

.MuiLinearProgress-bar {
	border-radius: 10px !important;
	background: linear-gradient(90deg, rgba(237, 52, 43, 1) 0%, rgba(255, 208, 0, 1) 100%);
}

/* video::-webkit-media-controls {
	display: none !important;
} */

.fullscreen {
	width: 100vw !important;
	height: 100vh !important;
}

.disabled-link {
	pointer-events: none;
	cursor: default;
}

.top-shadow {
	box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 23%), 4px 10px 30px 8px rgb(0 0 0 / 40%);
}

canvas {
	touch-action: none;
}

.wall-swiper-holder {
	position: relative;
}

.wall-swiper {
	position: static !important;
}

.wall-swiper .swiper-button-prev {
	left: -8px;
}

.wall-swiper .swiper-button-next {
	right: -8px;
}

.wall-swiper .swiper-button-prev::after,
.wall-swiper .swiper-button-next::after {
	font-size: 36px;
}

.go-btn {
	color: white;
	outline: none;
	border: 0;
	vertical-align: middle;
	text-decoration: none;
	font-size: inherit;
	font-family: inherit;
	padding: 1.25em 1em;
	/* background: #3f9815; */
	background-color: #45904f;
	/* border: 2px solid #27640b; */
	border: 3px solid #2e6b30;
	border-radius: 4em;
	transform-style: preserve-3d;
	transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
}

.go-btn::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* background: #107c10; */
	background-color: #347936;
	border-radius: inherit;
	box-shadow: 0 0 0 2px #27640b, 0 0.625em 0 0 #7eaa6a;
	transform: translate3d(0, 0.75em, -1em);
	transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.go-btn:active {
	background-color: #45904f;
	transform: translate(0em, 0.75em);
}

.go-btn:active::before {
	box-shadow: 0 0 0 2px #27640b, 0 0 #7eaa6a;
	transform: translate3d(0, 0, -1em);
}

.go-btn-no-shadow {
	box-shadow: 0 0 0 2px #27640b;
}

.go-btn.complete {
	background-color: #d3d3d3;
	border: 3px solid #686868;
	color: #686868;
	padding: 0.5em 1em;
}

.go-btn.complete::before {
	background-color: #686868;
	box-shadow: 0 0 0 2px #a0a0a0, 0 0.625em 0 0 #cfcfcf;
}

.go-btn.complete:active {
	background-color: #45904f;
}

.go-btn.complete:active::before {
	box-shadow: 0 0 0 2px #27640b, 0 0 #7eaa6a;
}

progress {
	-webkit-appearance: none;
}
progress::-webkit-progress-bar {
	background-color: #27640b;
	padding: 3px;
	border-radius: 1em;
}

.complete progress::-webkit-progress-bar {
	background-color: #686868;
}

progress::-webkit-progress-value {
	background-color: #f4ca4e;
	border-radius: 1em;
}

.intro-content h2 {
	font-weight: bold;
	font-size: 1.8em;
	line-height: 1;
}

.intro-content .swipe-up {
	font-size: 1.4em;
	z-index: 100;
	bottom: 2em;
}

.intro-content .swipe-up img {
	animation: swipe 1.7s ease-in-out infinite;
}

/* progress[value] {
	-webkit-appearance: none;
	appearance: none;

	width: 250px;
	height: 20px;
}

progress[value]::-webkit-progress-bar {
	background-color: #eee;
	border-radius: 2px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
} */

@keyframes swipe {
	0% {
	}

	50% {
		transform: translate(0, -0.5em);
	}

	100% {
		transform: translate(0, 0);
	}
}

.about h2 {
	font-weight: bold;
	font-size: 1.8em;
	line-height: 1;
}

.about h3 {
	font-weight: bold;
	font-size: 1.8em;
	line-height: 1;
	padding: 0.4em 0;
	padding-top: 1em;
}

.about {
	color: #363636;
}

.terms-conditions-page p {
	margin: 1em 0;
}

.terms-conditions-page ol {
	display: block;
	list-style-type: decimal;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}

.terms-conditions-page li {
	display: list-item;
	text-align: -webkit-match-parent;
}

.terms-conditions-page li::marker {
	unicode-bidi: isolate;
	font-variant-numeric: tabular-nums;
	text-transform: none;
	text-indent: 0px !important;
	text-align: start !important;
	text-align-last: start !important;
}
