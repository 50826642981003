.splash-loader {
	position: relative;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
}

.splash-loader .splash-loader-before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: -3px -3px;
	z-index: -1;
	border-radius: 50%;
	border: 3px solid #ccc;
	animation: 4s load linear forwards;
}

.splash-loader::before {
	content: '0%';
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	border: none !important;
	animation: 3s countUp linear forwards;
}

.splash-loader .splash-loader-after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: -3px -3px;
	z-index: -2;
	border-radius: 50%;
	background: linear-gradient(45deg, #204a96, #80277f);
}

.splash-screen {
	background: url('../../../assets/image/splashScreen.jpeg') center center;
	background-size: cover;
	background-position: center center;
}

@keyframes load {
	25% {
		border-top: none;
	}
	50% {
		border-right: none;
	}
	75% {
		border-bottom: none;
	}
	100% {
		border: none;
	}
}

@keyframes countUp {
	25% {
		content: '25%';
	}
	50% {
		content: '50%';
	}
	75% {
		content: '75%';
	}
	100% {
		content: '100%';
	}
}
